<template>
  <v-container
    fluid
    fill-height
    :class="$style.auth"
    class="d-flex justify-center align-center flex-column pa-0"
  >
    <v-overlay :value="processing">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div>
      <v-row dense>
        <v-col cols="12" class="text-center">
          <img :src="require('@/assets/logo.png')" width="100" />
        </v-col>
      </v-row>
      <v-row dense class="mb-4 mt-1">
        <v-col cols="12" class="text-center">
          <h2 :class="$style.title">{{ title }}</h2>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" class="d-flex justify-center">
          <v-card
            elevation="0"
            color="#fff"
            class="py-0 pa-4 rounded-xl"
            rounded
            :class="$style.form"
            width="360"
          >
            <v-row dense>
              <v-col cols="12">
                <v-container class="pa-0">
                  <v-row dense>
                    <v-col cols="12">
                      <div class="pa-3" v-html="privacyPolicy"></div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import MyForm from "@/entries/front/components/form";
export default {
  components: {
    MyForm
  },
  data() {
    return {
      formKey: "signup-privacy-policy",
      title: "プライバシーポリシー",
      privacyPolicy: `
        プライバシーポリシー....<br>
        プライバシーポリシー....<br>
        プライバシーポリシー....<br>
        プライバシーポリシー....<br>
        プライバシーポリシー....<br>
      `
    };
  },
  computed: {
    isError() {
      return this.$store.getters["auth/isError"];
    },
    processing() {
      return this.$store.getters[`auth/processing`];
    },
    entity() {
      return this.$store.getters[`auth/entity`];
    },
    errorMessages() {
      return this.$store.getters[`auth/errorMessages`];
    }
  },
  methods: {
    async validate(refName) {
      const providor = this.$refs[`v-${refName}`];
      const { valid } = await providor.validate();

      this.$nextTick(() => {
        this.valid[refName] = valid;
      });
    },
    popstate() {
      window.history.pushState(null, null, null);
      return;
    }
  },
  created() {
    this.$store.commit(`auth/initialize`);
  },
  mounted() {
    window.addEventListener("popstate", this.popstate);
    this.popstate();
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.popstate);
    this.$store.commit("form/changeDirty", [
      {
        key: this.formKey,
        isDirty: !!this.mailAddress
      }
    ]);
  }
};
</script>

<style module lang="scss">
@import "@/styles/common.scss";
.title {
  font-size: 16px;
  color: #6f808c;
  font-weight: 600;
}

.error {
  color: #ff1100;
  text-align: center;
  font-size: 12px;
}

.link {
  font-size: 16px;
  text-decoration-line: none;
  color: $base-font-color !important;
}
.error-messages {
  margin: 0;
  margin-top: 8px !important;
  padding-left: 46px !important;
  & > li {
    list-style-type: none;
    min-height: 23px;
    line-height: 12px;
    font-size: 12px;
    color: #ff5252 !important;
    caret-color: #ff5252 !important;
  }
}
</style>

<style scope lang="scss">
.theme--light.v-btn.v-btn--disabled {
  color: #fff !important;
}
</style>
